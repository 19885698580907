<template>
  <section class="sub-page iframe">
    <!-- herosection -->
    <div class="relative mt-20 mb-20">
      <img
        src="/theme-assets/images/dashboardImg.png"
        alt=""
        class="h-[260px] w-full"
      />
      <div
        class="absolute md:top-[100px] top-20 left-[15%] md:left-[25%] right-[15%] md:right-[25%] text-center"
      >
        <div class="py-10 px-10 md:px-20 bg-[#262637]">
          <p style="color: #1cfa78" class="text-[#1cfa78] text-4xl">
            Housing Data
          </p>
          <p class="text-[#8c8c92]">
            Get easy access to latest housing market data for Nigeria
          </p>
          <div class="flex md:gap-8 mt-8 md:flex-row flex-col gap-4">
            <select
              id="states"
              name="states"
              class="w-full rounded-sm p-2 md:w-[50%]"
            >
              <option v-for="(state, i) in states" :key="i" :value="state">
                {{ state.stateName }}
              </option>
            </select>
            <button
              class="bg-[#1cfa78] text-[#262637] px-4 rounded-sm"
              @click="filterByState()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- tabs -->

    <div class="mx-auto w-[80%]">
      <div>
        <div class="relative right-0">
          <div class="w-[50%]">
            <ul
              class="relative flex flex-wrap p-1 list-none"
              data-tabs="tabs"
              role="list"
            >
              <li class="z-30 flex-auto text-center">
                <a
                  class="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg cursor-pointer text-slate-700 bg-inherit"
                  data-tab-target=""
                  active=""
                  role="tab"
                  aria-selected="true"
                  aria-controls="app"
                >
                  <span class="ml-1">Overview</span>
                </a>
              </li>

              <li class="z-30 flex-auto text-center">
                <a
                  class="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg cursor-pointer text-slate-700 bg-inherit"
                  data-tab-target=""
                  role="tab"
                  aria-selected="false"
                  aria-controls="settings"
                >
                  <span class="ml-1">Compare</span>
                </a>
              </li>
            </ul>
          </div>
          <hr class="border-1 border-black" />

          <div data-tab-content="" class="mx-auto mt-10 mb-20">
            <!-- overview -->
            <div
              id="app"
              class="opacity-100 flex items-center justify-between"
              role="tabpanel"
            >
              <div class="w-[60%]">
                <div class="flex items-end">
                  <div>
                    <img
                      src="/theme-assets/images/map1.png"
                      alt=""
                      class="w-[721px] h-[529px]"
                    />
                  </div>

                  <div class="rounded-lg px-4 py-6 bg-[#f2f2f2]" />
                </div>
              </div>
              <div class="w-[40%] mt-20 bg-[#f2f2f2] rounded-lg py-8 px-4">
                <div class="border-2 border-black mb-6 rounded-md p-2">
                  Rental Housing
                </div>
                <div class="border-2 border-black mb-6 rounded-md p-2">
                  Primary Rental Market
                </div>
                <div class="border-2 border-black mb-6 rounded-md p-2">
                  Secondary Rental Market
                </div>
                <div class="border-2 border-black mb-6 rounded-md p-2">
                  New Housing Construction
                </div>
                <div class="border-2 border-black mb-6 rounded-md p-2">
                  Population Stock
                </div>
                <div class="border-2 border-black mb-6 rounded-md p-2">
                  Core Housing Need
                </div>
              </div>
            </div>

            <div
              id="message"
              class="hidden opacity-0 mx-auto mt-10"
              role="tabpanel"
            >
              <p>
                Select Location using dropdown or the intereactive map below
              </p>
            </div>

            <!-- Compare -->
            <div id="settings" class="hidden opacity-0" role="tabpanel">
              <div>
                <div class="bg-[#edf5ff] p-4 mx-auto rounded-lg">
                  <p class="text-[#c3c6ca] pb-2">
                    Average Price of Completed Hosues by state and geographical
                    location
                  </p>
                  <div class="flex gap-8 text-[#86888c]">
                    <p>Geological Location</p>
                    <p>South South</p>
                    <p>South East</p>
                  </div>
                  <div class="mt-4">
                    <img
                      src="/theme-assets/images/Chart (4).png"
                      alt=""
                      class=""
                    />
                  </div>
                  <div class="flex gap-8 flex-row justify-end">
                    <p>Export as</p>
                    <p>Embed Code</p>
                    <p>API</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import states from "./states.json";

export default {
  name: "HousingPortal",
  components: {},

  data: () => ({
    nav_title: "Housing Data Portal",
    navs: [
      {
        route_name: "housing.portal",
        route_title: "Housing Portal",
      },
      {
        route_name: "housing-price",
        route_title: "Housing Price",
        active: true,
      },
    ],
    visible: true,
    iframe: {
      src: "https://app.powerbi.com/view?r=eyJrIjoiNWVhNTM4OTItNGMwNS00MjIwLWI5ZWUtYWQ4MzQxNmMwNTAyIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9",
      loaded: false,
    },
    states: states,
  }),

  methods: {
    load() {
      this.iframe.loaded = true;
    },
    filterByStates() {},
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>
